.blog-container {
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto';
  padding: 20px;
  margin-bottom: 20px;
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.blog-container::-webkit-scrollbar {
  display: none;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 80vh;
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto';
  padding: 20px;
  margin-bottom: 20px;
}

.loading-image {
  width: 120px; 
  height: 120px;
}

.search-container {
  position: relative;
  display: inline-block;
}

.blog-container input {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid black;
  width: 200px;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-100%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  color: red;
  font-weight: bolder;
}

.clear-button:hover {
  opacity: 0.8;
}

.blog-posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    overflow-y: auto;
  }

  .blog-title {
    text-align: center;
    font-family: 'Cookie';
    font-size: 2.5rem;
    letter-spacing: 0.01em;
  }

  .blog-post {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    background-color: #4a90e21a;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .blog-post-content {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .blog-post img {
    width: 25%;
    margin-right: 25px;
  }
  
  .blog-post a {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }  

  .blog-posts-container::-webkit-scrollbar {
    display: none;
  }
  
  .blog-posts-container {
    scrollbar-width: none;
  }

  .blogPost {
    padding: 50px;
  }

  .blogPost img {
    width: 80%;
    display: block;
    margin: 0 auto;
    padding: 10px;
  }

  .blogTitle {
    text-align: center;
  }

  .blogDesc {
    text-align: center;
    color: gray;
    margin: 20px;
  }
  

  .load-more-button {
    display: block;
    margin: 20px auto 20px auto;
    padding: 10px 20px;
    border: none;
    background-color: #01294b;
    color: white;
    border-radius: 12px;
    cursor: pointer;
    max-height: 50px;
  }

.author-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.author-details {
  display: flex;
  align-items: center;
  flex-basis: 50%;
}

.author-details img {
  width: 50px;
  height: 50px;
  margin: 0 10px 0 0;
  border-radius: 50%;
}

.author-details p {
  margin: 0;
  margin-right: 5px;
}

.date-details {
  flex-basis: 50%;
  text-align: right;
}

.divider {
  border: 1px solid black;
}

.shareTitle {
  text-align: center;
  font-family: 'Cookie';
  font-size: 2.5rem;
  letter-spacing: 0.01em;
  margin-bottom: -10px;
}

.share-widget {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  height: 80px;
  justify-content: center;
  align-items: center;
}

.share-widget button {
  background-color: #01294b;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: white;
}

.share-widget button:hover {
  color: #0077cc;
}
  
@media (max-width: 768px) {
  .blog-container {
    width: 80%;
  }
  .blog-posts-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .blog-post {
    flex-direction: column;
  }

  .blog-post img {
    width: 100%;
    height: auto;
    margin-right: 0px;
  }
}
  