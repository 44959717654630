.App {
  display: flex;
  flex-direction: column;
  background-image: url('./images/bg.jpg');
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  min-height: 100vh;
}

.routes {
  flex: 1;
  margin: 50px auto 0px auto;
  width: 1024px;
}

@media (max-width: 1024px) {
  .routes {
    margin: 20px auto 0px auto;
    max-width: 800px;
    width: 100%;
  }
}