.admin-container {
    width: 100%;
    margin: 0 auto;
    font-family: 'Roboto';
    padding: 20px;
    margin-bottom: 20px;
    background-image: url("../images/bg-white.png");
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    padding-bottom: 50px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .admin-container::-webkit-scrollbar {
    display: none;
  }

  .dashboard h2 {
    text-align: center;
    font-family: 'Cookie';
    font-size: 2.5rem;
    letter-spacing: 0.01em;
  }

  .dashboard select {
    margin-bottom: 20px;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    margin: 20px;
  }
  
  .button-grid button {
    width: 100%;
    height: 100%;
    border: 2px solid black;
    background-color: #a6dbfa;
    border-radius: 50%;
    margin: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 100px; 
    font-size: small;
    font-family: 'Roboto';
    font-weight: bold;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
    
  @media (max-width: 768px) {
    .admin-container {
      width: 80%;
    }
  }
    