.dropdown {
    padding: 5px;
    margin: 10px auto;
    border: 1px solid #000000;
    border-radius: 4px;
}

.input {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: auto;
  }
  
  .button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Add :hover state for button for better interactivity */
  .button:hover {
    background-color: #0056b3;
  }

  .calculator-image img{
    width: 250px;
    height: auto;
    margin: 10px;
  }

  .sump-image img{
    width: auto;
    height: 230px;
    margin: 10px;
  }

  .icon-button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0 2px;
    margin: 0 5px;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .slider {
    width: 60%;
    margin: 0 auto;
  }

  .calculation {
    font-weight: bold;
  }

  .conversion-arrow {
    margin: 0px 10px;
  }
  
  