.calculator-page-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    font-family: 'Roboto';
    background-image: url("../images/bg-white.png");
    background-size: cover;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    height: 80vh;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .calculator-page-container::-webkit-scrollbar {
    display: none;
  }

  .calculator-header {
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.01em;
    font-family: 'Cookie';
    font-size: 2.5rem;
  }
  .calculators {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
  }
  .calculator-nav {
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
  }
  
  .calc-button {
    display: block;
    margin: 6px auto 6px auto;
    width: 150px;
    padding: 10px 20px;
    border: none;
    background-color: #93DBEF;
    color: #12496A;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
  }
  
  .calc-button.active {
    background-color: #76C1D9;
  }
  
  .calculator-display {
    flex: 1;
    border-radius: 8px;
    margin: 20px;
    min-width: 0;
    text-align: center;
  }

  .volume-dropdown {
    background-color: #cfecf7; /* Lighter shade for dropdown */
    border-radius: 8px;
    text-align: center;
    margin: -6px auto 0px auto;
    padding-bottom: 5px;
    width: 125px;
  }
  
  .dropdown-item {
    display: block;
    margin: 0px auto 0px auto;
    width: 125px;
    padding: 7px;
    border: none;
    background-color: #cfecf7;
    color: #12496A;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #93DBEF;
  }

  .dropdown-item.active {
    background-color: #62c1e5;
  }

