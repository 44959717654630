.about-container {
    width: 100%;
    margin: 0 auto;
    font-family: 'Roboto';
    padding: 20px;
    margin-bottom: 20px;
    background-image: url("../images/bg-white.png");
    background-size: cover;
    border-radius: 12px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    height: 80vh;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .about-container::-webkit-scrollbar {
    display: none;
  }
  
  .about-content {
    padding: 20px;
    margin: 0 auto;
  }
  
  .welcome-title {
    text-align: center;
    font-family: 'Cookie';
    font-size: 2.5rem;
    letter-spacing: 0.01em;
  }

  .logo-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 30px auto;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 2px solid rgb(10, 118, 133);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  .logo-img img {
    width: 70%;
    height: auto;
  }
  
  .section {
    margin: 0 auto 40px auto;

  }

  .section h2 {
    text-align: center;
    font-family: 'Cookie';
    font-size: 2rem;
    letter-spacing: 0.01em;
  }

  .section p {
    letter-spacing: 0.01em;
    line-height: 1.4em;
  }

  /* Form Styling */
  .section form {
    margin: 0px auto;
    background-color: #4a90e21a;
    padding: 20px 30px;
    border-radius: 12px;
    border: 1px solid #ccc;
    opacity: 90%;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .section label {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .section input[type="email"] {
    width: 70%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    resize: vertical;
  }

  .section textarea {
    width: 97%;
    min-height: 150px;
    max-height: 400px;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    resize: vertical;
  }

  .section button[type="submit"] {
    display: inline-block;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s;
  }

  .section button[type="submit"]:hover {
    background-color: #555;
  }
    
  .about-us {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .team-section {
    text-align: center;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 20px;
    background-color: #4a90e21a;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .team-member-2 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 20px;
    background-color: #4a90e21a;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .member-img {
    width: 25%;
    height: 25%;
    border-radius: 50%;
  }
  
  .member-description {
    flex: 1;
    margin-left: 20px;
    text-align: left;
    font-size: 17px;
    word-spacing: 0.03em;
    line-height: 1.3em;
  }

  .member-description-2 {
    flex: 1;
    margin-right: 20px;
    text-align: left;
    font-size: 17px;
    word-spacing: 0.03em;
  }
  
  @media (max-width: 768px) {
    .about-container {
      width: 80%;
    }

    .about-us {
      flex-direction: column;
    }

    .team-member {
        flex-direction: column;
      }

    .member-img {
        margin: 10px auto;
        width: 60%;
        height: 60%;
        max-width: 200px;
    }
      
    .member-description {
        margin: 10px auto;
        max-width: 90%;
      }

    .team-member-2 {
        flex-direction: column;
      }

    .member-description-2 {
        margin: 10px auto;
        max-width: 90%;
      }
  }
  