/* Container */
.support-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-family: 'Roboto';
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  min-height: 80vh; /* Ensure the container has a minimum height */
  overflow-y: auto;
  scrollbar-width: none;
}

.support-container::-webkit-scrollbar {
  display: none;
}

/* Header Section */
.support-header {
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.01em;
  font-family: 'Cookie';
  font-size: 2.5rem;
}

/* Support Text */
.support-text {
  text-align: center;
  font-size: 18px;
  width: 90%;
  margin: 20px auto;
  color: #333;
}

/* Donation Section and Merchandise Section */
.support-section {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Roboto';
  background-color: #4a90e21a;
  border-radius: 12px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensure it can expand */
  align-items: center; /* Center align the iframe */
}

.button-section {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
}

/* Button */
.support-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 300px;
  background-color: #317FAC;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: .01em;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.support-button:hover {
  background-color: #01294b;
}

/* Embed Merchandise */
.support-iframe {
  border-radius: 24px;
  border: none;
  width: 100%; /* Ensure the iframe takes full width of its container */
  height: 420px; /* Set a fixed height */
  overflow-y: auto;
  scrollbar-width: none;
}

@media (max-width: 768px) {
  .support-container {
      width: 80%;
  }

  .support-section {
      width: 80%;
  }

  .button-section {
      flex-direction: column;
  }

  .support-button {
      width: 250px;
      margin: 0 auto 15px auto;
  }

  .support-iframe {
      width: 100%;
      height: 420px;
  }
}
