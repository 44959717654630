.glossary-container {
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto';
  padding: 20px;
  margin-bottom: 20px;
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.glossary-container::-webkit-scrollbar {
  display: none;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 80vh;
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto';
  padding: 20px;
  margin-bottom: 20px;
}

.loading-image {
  width: 120px; 
  height: 120px;
}

.header {
  text-align: center;
}  

.header-title {
  text-align: center;
  font-family: 'Cookie';
  font-size: 2.5rem;
  letter-spacing: 0.01em;
}

.search-container {
  position: relative;
  display: inline-block;
}

.header input {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid black;
  width: calc(100% - 10px);
  box-sizing: border-box;
}

.clear-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-100%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  color: red;
  font-weight: bolder;
}

.clear-button:hover {
  opacity: 0.8;
}

.header select {
  margin: 10px;
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
}

.items {
  background-color: #4a90e21a;
  padding: 15px 30px;
  border-radius: 12px;
  width: 90%;
  margin: 0 auto;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

ul {
  list-style-type: none;
  padding: 0;
  max-height: 60vh;
  overflow-y: auto;
  scrollbar-width: none;
}

ul::-webkit-scrollbar {
  display: none;
}

li {
  margin-bottom: 20px;
}

li span {
  display: inline-block;
}

strong {
  font-size: 18px;
}

li p {
  display: inline;
  margin-left: 5px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .glossary-container {
    width: 80%;
  }

  .header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .items {
    width: 75%;
  }
}
