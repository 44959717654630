.home-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  font-family: 'Roboto';
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 80vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.home-container::-webkit-scrollbar {
  display: none;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/bg-white.png");
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
  height: 80vh;
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto';
  padding: 20px;
  margin-bottom: 20px;
}

.loading-image {
  width: 120px; 
  height: 120px;
}

.home-container h1 {
  text-align: center;
  font-family: 'Cookie', cursive;
  font-size: 2.5rem;
  letter-spacing: 0.01em;
}

.featured-content {
  position: relative;
  height: 420px;
  background-size: cover;
  border-radius: 10px;
}

.text {
  color: white;
  padding: 30px;
  z-index: 2;
  width: 80%;
  position: absolute; 
  top: 0;
  left: 0%;
}

.text h4 {
  background-color: #005073;
  width: 64px;
  margin-left: 2px;
  padding: 3px;
  border-radius: 3px;
}

.text button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.text a {
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.darken {
  background-color: black;
  z-index: 1;
  opacity: 30%;
  height: 420px;
  width: 100%;
  border-radius: 10px;
  position: absolute; 
  top: 0; 
  left: 0; 
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 550px;
}

.event-container {
  flex: 0.6;
  padding: 20px;
  font-family: 'Roboto';
  background-color: #4a90e21a;
  border-radius: 12px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  height: 80%;
  display: flex;
  flex-direction: column;
}

.event-container h2 {
  margin: 10px 0px 0px 0px;
}

.event-slide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto; 
  margin-bottom: 20px;
  position: relative;
}

.event-details {
  flex: 1;
  padding-right: 20px;
  height: auto;
  margin-bottom: 10px; 
}

.event-slide img {
  max-width: 40%; 
  height: auto;
  border-radius: 10px;
}

.calendar-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px auto; 
}

/* Hide the slide input radio buttons */
.slide-input {
  display: none;
}

/* By default, hide all slides */
.slide {
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
}

/* Show the slide when its corresponding input radio is checked */
.slide-input:checked + .slide {
display: block;
opacity: 1;
}

/* Style the slider controls */
.slider-controls {
display: flex;
gap: 10px;
justify-content: center;
}

.slider-label {
cursor: pointer;
padding: 5px 10px;
background-color: none;
border: none;
transition: background-color 0.3s;
}

.label-icon {
  color: #5e5c5c;
  opacity: 30%;
  }

.label-icon:hover {
color: #aaa4a4;
border-radius: 50px;
opacity: 100%;
}


.podcast-content {
  flex: 0.4;
  padding: 20px;
}

.podcast-content a {
  text-decoration: none;
  color: #005073;
}

#player_iframe {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 12px;
  width: 100%;
  height: 380px;
}

@media (max-width: 768px) {
  .home-container {
    width: 80%;
  }

  .content {
    flex-direction: column;
  }

  .podcast-content {
    padding: 0px;
    padding-bottom: 30px;
  }
  

  .event-container, .podcast-content {
    width: 90%;
    margin-top: 10px;
    height: auto;
  }

  .event-slide {
    flex-direction: column; 
    align-items: flex-start;
  }

  .event-slide img {
    display: none;
  }

  .event-details {
    width: 100%; 
    padding-right: 0px; 
  }

  .calendar-buttons {
    flex-direction: column; 
    align-items: center; 
    margin-top: 10px; 
    margin-bottom: -20px;
  }
}