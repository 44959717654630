.not-found-container {
    display: flex;
    flex-direction: column;
    margin: 0px auto 50px 0px;
    font-family: 'Roboto';
    background-image: url("../images/bg-white.png");
    background-size: cover;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
    height: 80vh;
    text-align: center;
  }
  
  .not-found-container h1 {
    font-size: 6rem;
    margin: 0px auto;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .home-link {
    font-size: 1.2rem;
    color: #007bff;
    text-decoration: none;
    border: 2px solid #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .home-link:hover {
    background-color: #007bff;
    color: white;
  }
  